import React from "react";
import Router from "next/router";
import Skeleton from '@material-ui/lab/Skeleton';
import { auth, getUser } from "./firebase";

const redirectToLogin = () => Router.push("/login");

const withAuth = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authenticated: false,
        user: null,
        loading: true,
      };
    }

    componentDidMount() {
      auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          const ref = this;
          getUser(authUser.email).then(function (doc) {
            ref.setState({
              authenticated: true,
              user: authUser,
              userData: doc.data(),
              loading: false,
            });
          });
        } else {
          this.setState({
            authenticated: false,
            user: null,
            userData: null,
            loading: false,
            redirectToLogin: redirectToLogin,
          });
        }
      });
    }

    renderContent() {
      const { loading, user } = this.state;
      if (loading) {
        return <Skeleton variant="rect" height={50} />;
      } else if (!loading) {
        return <Component {...this.props} auth={this.state} />;
      }
    }

    render() {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    }
  };
};
export default withAuth;
