import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// import { addPost, addUserFetcher } from "./stream";
import keywords from "./keywords";
import { useEffect, useState } from "react";
import { sendRegistrationEmail, sendFollowerNotification } from "./user";

var firebaseConfig = {
  apiKey:
    process.env.FIREBASE_API_KEY || "AIzaSyD2FNovHJAMPxHAPcbo05CR9k2B2-wEGWY",
  authDomain: process.env.FIREBASE_AUTH_DOMAIN || "btv-staging.firebaseapp.com",
  databaseURL:
    process.env.FIREBASE_DATABASE_URL || "https://btv-staging.firebaseio.com",
  projectId: process.env.FIREBASE_PROJECT_ID || "btv-staging",
  storageBucket:
    process.env.FIREBASE_STORAGE_BUCKET || "btv-staging.appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "782458520984",
  appId:
    process.env.FIREBASE_APP_ID || "1:782458520984:web:4e0f94933e8c1dcfca97ed",
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || null,
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const fs = firebase.storage();
const fv = firebase.firestore.FieldValue;
const auth = firebase.auth();

const createPost = async (email, post, id) => {
  try {
    await db
      .collection("users")
      .doc(email)
      .collection("uploads")
      .doc(id)
      .set(post);
    console.log("upload cleanup");
    db.collection("upload_queue")
      .doc(id)
      .delete()
      .then(() => {
        console.log("done with cleanup");
        window.location.reload();
      });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const createUser = async (
  email,
  firstName,
  lastName,
  username,
  subscribe,
  like
) => {
  try {
    await db.collection("users").doc(email).set({
      firstName: firstName,
      lastName: lastName,
      username: username,
      email: email,
      following: [],
      followers: [],
      uploadNotifications: true,
      followerNotifications: true,
    });

    if (subscribe && subscribe.length > 0) {
      const users = await db
        .collection("users")
        .where("username", "==", subscribe)
        .get();

      if (!users.empty) {
        await followUser(email, users.docs[0].get("email"));
      }
    } else if (like && like.length > 0) {
      const uploads = await db
        .collectionGroup("uploads")
        .where("meta.id", "==", like)
        .get();

      if (!uploads.empty) {
        const upload = uploads.docs[0].ref;

        await upload.collection("likes").add({
          user: email,
          userName: username,
          created: new Date(),
        });

        await uploads.docs[0].ref.set(
          {
            meta: { likes: fv.increment(1) },
          },
          { merge: true }
        );
      }
    }
  } catch (error) {
    console.error("Error adding document: ", error);
  }
  sendRegistrationEmail(email, username, firstName);
};

const updateUser = async (email, data) => {
  return await db
    .collection("users")
    .doc(email)
    .update(data)
    .catch(function (error) {
      console.error("Error updating document: ", error);
    });
};

const getUser = async (email) => {
  return await db
    .collection("users")
    .doc(email)
    .get()
    .catch(function (error) {
      console.error("Error getting document: ", error);
    });
};

const registerUser = (
  email,
  password,
  firstName,
  lastName,
  username,
  subscribe,
  like
) => {
  email = email.toLowerCase();
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      createUser(email, firstName, lastName, username, subscribe, like);
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(`${errorCode} - ${errorMessage}`);
    });
};

function getAllSearchPosts(search) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function getPosts() {
      const values = keywords(search);

      if (values.length === 0) {
        setPosts([]);
      } else {
        let tagsRef = db
          .collection("tags")
          .where("keywords", "array-contains-any", values)
          .orderBy("priority");

        if (values.length < 2) {
          tagsRef = tagsRef.limit(10);
        }

        const tags = await tagsRef.get();

        if (tags.empty) {
          setPosts([]);
        } else {
          let tagRefs = [];

          if (values.length < 2) {
            tagRefs = tags.docs.map((t) => t.ref);
          } else {
            const lookup = [];
            tags.docs.forEach((tag) => {
              const points = tag
                .get("keywords")
                .filter((k) => values.includes(k)).length;
              if (points >= values.length) {
                const index = tagRefs.findIndex(
                  (f, i) => lookup[i].points < points
                );
                const spliceIndex = index === -1 ? tagRefs.length : index;
                tagRefs.splice(spliceIndex, 0, tag.ref);
                lookup.splice(spliceIndex, 0, points);
              }
            });
            tagRefs = tagRefs.splice(0, 10);
          }

          // Get all posts within any "uploads" collection.
          const uploadsRef = db
            .collectionGroup("uploads")
            .where("meta.tags", "array-contains-any", tagRefs)
            .orderBy("meta.created", "desc");

          const result = await uploadsRef.get();

          setPosts(result.docs.map((doc) => doc.data()));
        }
      }
    }

    getPosts();
  }, [search]);

  if (posts) return posts;
}

function getAllTagPosts(tag, limit) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function getPosts() {
      // Get all posts within any "uploads" collection.
      const tagRefs =
        tag === "ncaab" || tag === "ncaamb"
          ? [
              db.collection("tags").doc("ncaab"),
              db.collection("tags").doc("ncaamb"),
            ]
          : [db.collection("tags").doc(tag)];

      let uploadsRef = db
        .collectionGroup("uploads")
        .where("meta.tags", "array-contains-any", tagRefs)
        .orderBy("meta.created", "desc");

      if (limit) {
        uploadsRef = uploadsRef.limit(limit);
      }

      const result = await uploadsRef.get();

      setPosts(result.docs.map((doc) => doc.data()));
    }

    getPosts();
  }, [tag]);

  if (posts) return posts;
}

function getAllRelatedPosts(upload) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function getPosts() {
      let results = [];

      const uploadRef = await db.doc(upload).get();
      const games = uploadRef.get("meta.games").splice(0, 10);
      const tags = uploadRef.get("meta.tags").splice(0, 10);
      const createdAt = uploadRef.get("meta.created");

      const removeDuplicateUploads = (uploadArr) => {
        let resultsIds = results.map((result) => result.id);
        let uniqueUploads = uploadArr.filter((upload) => {
          return !resultsIds.includes(upload.id) && upload.id !== uploadRef.id;
        });
        return uniqueUploads;
      };

      // +/- 12 hour time contraint for relevance
      const lowerBound = new Date((createdAt.seconds - 43200) * 1000);
      const upperBound = new Date((createdAt.seconds + 43200) * 1000);

      if (games.length > 0) {
        // 1. Videos related to the same game
        const relatedByGameRef = db
          .collectionGroup("uploads")
          .where("meta.games", "array-contains-any", games)
          .orderBy("meta.created", "desc")
          .limit(6);

        const relatedByGameResult = (await relatedByGameRef.get()).docs;
        const uniqueRelatedByGameResult =
          removeDuplicateUploads(relatedByGameResult);
        results = [...results, ...uniqueRelatedByGameResult];
      }

      if (results.length < 5) {
        // query db once to get all post from within time bounds
        const sameDayUploadQuery = db
          .collectionGroup("uploads")
          .where("meta.created", ">=", lowerBound)
          .where("meta.created", "<=", upperBound)
          .orderBy("meta.created", "desc")
          .limit(20);

        const sameDayUploadRef = await sameDayUploadQuery.get();
        const leaguesRef = await db.collection("leagues").get();

        // associate leagues to sports
        const leagueMapping = {};
        leaguesRef.docs.forEach((league) => {
          const leagueData = league.data();
          leagueMapping[leagueData.slug] = leagueData.sport;
        });

        // sports leagues that are tagged in upload
        const leagueTagsRef = tags.filter((item) => {
          return Object.keys(leagueMapping).includes(item.id);
        });
        const leagueTags = leagueTagsRef.map((doc) => doc.id);

        // get all related leagues for associated upload
        let relatedLeagues = [];
        leagueTagsRef.forEach((leagueTag) => {
          const sportKey = leagueMapping[leagueTag.id];
          Object.keys(leagueMapping).forEach((league) => {
            if (leagueMapping[league] === sportKey) {
              relatedLeagues.push(league);
            }
          });
        });

        if (results.length < 5) {
          // 2. Find unique uploads related to the same league posted on the same day
          const relatedUploadsByLeague = sameDayUploadRef.docs.filter((doc) => {
            let { meta } = doc.data();
            return (
              meta.tags.filter((tag) => leagueTags.includes(tag.id)).length > 0
            );
          });
          const uniqueRelatedUploadsByLeague = removeDuplicateUploads(
            relatedUploadsByLeague
          );
          results = [...results, ...uniqueRelatedUploadsByLeague];
        }

        if (results.length < 5) {
          // 3. Find unique uploads related to the same sport posted on the same day
          const relatedUploadsBySport = sameDayUploadRef.docs.filter((doc) => {
            let { meta } = doc.data();
            return (
              meta.tags.filter((tag) => relatedLeagues.includes(tag.id))
                .length > 0
            );
          });
          const uniqueRelatedUploadsBySport = removeDuplicateUploads(
            relatedUploadsBySport
          );
          results = [...results, ...uniqueRelatedUploadsBySport];
        }

        if (results.length < 5) {
          // 4. Find unique uploads posted on the same day
          const previousDayPosts = sameDayUploadRef.docs.map((doc) => doc);
          const uniquePreviousDayPosts =
            removeDuplicateUploads(previousDayPosts);
          results = [...results, ...uniquePreviousDayPosts];
        }
      }

      if (results.length < 5) {
        // 5. Display any other unique uploads
        const uploadsQuery = db
          .collectionGroup("uploads")
          .orderBy("meta.created", "desc")
          .limit(6);

        const uploadsRef = (await uploadsQuery.get()).docs;
        const uniqueUploads = removeDuplicateUploads(uploadsRef);
        results = [...results, ...uniqueUploads];
      }

      const resultsData = results.map((result) => result.data());
      setPosts(resultsData.slice(0, 4));
    }
    getPosts();
  }, [upload]);

  if (posts) return posts;
}

function getAllGamePosts(game) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function getPosts() {
      // Get all posts within any "uploads" collection.
      const uploadsRef = db
        .collectionGroup("uploads")
        .where("meta.games", "array-contains", db.doc(game))
        .orderBy("meta.created", "desc");

      const result = await uploadsRef.get();

      setPosts(result.docs.map((doc) => doc.data()));
    }

    getPosts();
  }, [game]);

  if (posts) return posts;
}

function getAllUserPosts(author) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function getPosts() {
      // Get all posts within any "uploads" collection.
      const uploadsRef = db
        .collection("users")
        .doc(author)
        .collection("uploads")
        .orderBy("meta.created", "desc");

      const result = await uploadsRef.get();

      setPosts(result.docs.map((doc) => doc.data()));
    }

    getPosts();
  }, [author]);

  if (posts) return posts;
}

// function getFollowers(user) {
//   const [followers, setFollowers] = useState([]);

//   useEffect(() => {
//     async function getAllFollowers() {
//       let allFollowers = [];

//       const userRef = db.collection("users").doc(user);
//       const result = await userRef.get();
//       result.data().followers.forEach(async function (doc) {
//         const followerRef = db.doc(doc.path);
//         await followerRef.get().then((doc) => allFollowers.push(doc.data()));

//         if (allFollowers.length === result.data().followers.length) {
//           setFollowers(allFollowers);
//         }
//       });
//     }

//     getAllFollowers();
//   }, []);

//   return followers;
// }

// function getFollowing(user) {
//   const [following, setFollowing] = useState([]);

//   useEffect(() => {
//     async function getAllFollowing() {
//       let allFollowing = [];

//       const userRef = db.collection("users").doc(user);
//       const result = await userRef.get();
//       result.data().following.forEach(async function (doc) {
//         const followingRef = db.doc(doc.path);
//         await followingRef.get().then((doc) => allFollowing.push(doc.data()));

//         if (allFollowing.length === result.data().following.length) {
//           setFollowing(allFollowing);
//         }
//       });
//     }

//     getAllFollowing();
//   }, []);

//   return following;
// }

async function getChunk(chunkSize, lastOfChunk, selector) {
  function getVideoRefs({ type, filter }) {
    switch (type) {
      case "all":
        return db.collectionGroup("uploads").orderBy("meta.created", "desc");
      case "user":
        return db
          .collection("users")
          .doc(filter)
          .collection("uploads")
          .orderBy("meta.created", "desc");
      case "tag":
        return db
          .collectionGroup("uploads")
          .where(
            "meta.tags",
            "array-contains",
            db.collection("tags").doc(filter)
          )
          .orderBy("meta.created", "desc");
      default:
        return null;
    }
  }

  // Get all posts within any "uploads" collection.
  let uploadsRef = getVideoRefs(selector);

  // initial chunk
  if (chunkSize && !lastOfChunk) {
    uploadsRef = uploadsRef.limit(chunkSize);
  }

  // sequential chunk
  if (chunkSize && lastOfChunk) {
    uploadsRef = uploadsRef.startAfter(lastOfChunk).limit(chunkSize);
  }

  const result = await uploadsRef.get();
  const lastResultRef = result.docs[result.docs.length - 1];

  const chunkedResults = result.docs.map((doc) => doc.data());

  return { chunkedResults, lastResultRef };
}

async function getAllUsers() {
  const usersRef = db.collection("users");

  const result = await usersRef.get();

  return result.docs.map((doc) => doc.data());
}

function getUpload(id) {
  const [uploadState, setUploadState] = useState(null);

  useEffect(() => {
    async function getPost(id) {
      await db
        .collectionGroup("uploads")
        .where("meta.id", "==", id)
        .get()
        .then((querySnapshot) => {
          setUploadState(querySnapshot.docs[0].data());
        });
    }

    if (id) {
      getPost(id);
    }
  }, [id]);

  if (uploadState) return uploadState;
}

async function followUser(followingUser, followedUser) {
  const followingUserRef = db.collection("users").doc(followingUser);
  const followedUserRef = db.collection("users").doc(followedUser);

  followingUserRef.set(
    {
      following: fv.arrayUnion(followedUserRef),
    },
    { merge: true }
  );

  followedUserRef.set(
    {
      followers: fv.arrayUnion(followingUserRef),
    },
    { merge: true }
  );

  const followed = await followedUserRef.get();
  console.log("followedUser", followed.data());

  const follower = await followingUserRef.get();
  console.log("followingUser", follower.data());

  if (followed.data().followerNotifications) {
    sendFollowerNotification(
      followed.data().email,
      followed.data().firstName,
      followed.data().username,
      follower.data().username
    );
  }
}

function unfollowUser(unfollowingUser, unfollowedUser) {
  const unfollowingUserRef = db.collection("users").doc(unfollowingUser);
  const unfollowedUserRef = db.collection("users").doc(unfollowedUser);

  unfollowingUserRef.set(
    {
      following: fv.arrayRemove(unfollowedUserRef),
    },
    { merge: true }
  );

  unfollowedUserRef.set(
    {
      followers: fv.arrayRemove(unfollowingUserRef),
    },
    { merge: true }
  );
}

async function isFollowingUser(user1, user2) {
  const user1Ref = db.collection("users").doc(user1);
  const user1Doc = await user1Ref.get();

  let followingUser;

  (user1Doc.data().following || []).forEach(async function (doc) {
    const followingRef = db.doc(doc.path);
    if (followingRef.id === user2) followingUser = true;
  });

  return followingUser;
}

// function getFeed(user) {
//   // get followers
//   // get follower posts
//   // build feed
// }

export {
  db,
  fs,
  fv,
  auth,
  createPost,
  registerUser,
  getUser,
  updateUser,
  getChunk,
  getAllUserPosts,
  getAllGamePosts,
  getAllRelatedPosts,
  getAllTagPosts,
  getAllSearchPosts,
  getAllUsers,
  getUpload,
  followUser,
  unfollowUser,
  isFollowingUser,
};
