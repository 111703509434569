import { clean } from "diacritic";

const keywords = (text, options) => {
  return clean(text)
    .toLowerCase()
    .trim()
    .split(/\W+/)
    .filter((word) => word.length >= 2)
    .splice(0, 10);
};

module.exports = keywords;
