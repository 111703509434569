import useSWR from "swr";
import axios from "axios";

const getFollowers = (user, currentUser) => {
  console.log("getFollowers -> user, currentUser", user, currentUser);
  const { data, error } = useSWR(["/api/followers"], () =>
    axios({
      method: "post",
      url: "/api/followers",
      data: {
        user,
        currentUser,
      },
    })
  );

  if (data) {
    console.log("Successfully got followers:", data);
    return data;
  }
};

const getFollowing = (user, currentUser) => {
  const { data, error } = useSWR(["/api/following"], () =>
    axios({
      method: "post",
      url: "/api/following",
      data: {
        user,
        currentUser,
      },
    })
  );

  if (data) {
    console.log("Successfully got following:", data);
    return data;
  }
};

const sendRegistrationEmail = (email, username, firstName) => {
  axios({
    method: "post",
    url: "/api/sendgrid",
    data: {
      email,
      username,
      firstName,
    },
  });
};

const sendFollowerNotification = (email, firstName, username, followerUsername) => {
  console.log(email, firstName, username, followerUsername);
  axios({
    method: "post",
    url: "/api/sendgrid-follower-notification",
    data: {
      email,
      firstName,
      username,
      followerUsername,
    },
  });
};

export { getFollowers, getFollowing, sendRegistrationEmail, sendFollowerNotification };
